import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from './context/AuthContext';

import StorePage from './page/StorePage'
import { useState } from 'react';
import './css/App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // black
      
    },
    secondary: {
      main: '#ffffff', // white
    },
    background:{
      default:'#F5EFE0',
      secondary:'#ffffff',
      gray: '#DEDEDE',
      component:'#3D3C35',
    },
    text:{
      primary: '#000000',
      secondary:'#787878',
      selected:'#98B147',
    },
        warning: {
      light: '#ffd166',  // your light warning color
      main: '#3D3C35',   // your main warning color
      dark: '#d06600',   // your dark warning color
      contrastText: '#fff',  // text color that will contrast with the warning color
    },
    selected:'#98B147',
  },
});
const router = createBrowserRouter(

  createRoutesFromElements([

    <Route >
      <Route path="/app" element={<></>} />
      <Route path='/app/store/:storeId' element={<StorePage/>}/>
    </Route>,
    <Route path="*" element=<Navigate to="/app" replace /> />



  ])
);
function App() {

  const [user, setUser] = useState(null);
  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{
        user: user,
        setUser: setUser
      }}>
        <RouterProvider router={router} />
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
