import axios from 'axios';

// Handle the Authorization header if token exists in localStorage


const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 10000, 
    headers: { 'Content-Type': 'application/json' }
});

const tokenFromStorage = localStorage.getItem('token');

if (tokenFromStorage) {

    api.defaults.headers.common['Authorization'] = `Bearer ${tokenFromStorage}`;
}



export default api;
