// OrderPage.js

import React, { useState, useEffect } from "react";
import {
  Container,
  Collapse,
  Typography,
  Toolbar,
  Box,
  Radio,
  Divider,
  CardContent,
  IconButton,
  AppBar,
  Checkbox,
  Button,
  List,
  Chip,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Stack,
  Card,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  ListItemButton,
} from "@mui/material";
import getProducts from "../api/product/getProducts";
import getStore from "../api/store/getStore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router-dom";
import { StoreLogo } from "../component/StoreLogo";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import StoreIcon from "@mui/icons-material/Store";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
import { useTheme } from "@mui/material/styles";
import { QRCodeSVG } from "qrcode.react";

const StorePage = () => {
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const storeIdParam = useParams();
  const theme = useTheme();
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [cart, setCart] = useState([]);
  const [openProductId, setOpenProductId] = useState(null);
  const [snakeOpen, setSnakeOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const [orderString, setOrderString] = useState("");
  const toggleCollapse = (id) => {
    if (openProductId === id) {
      setOpenProductId(null);
    } else {
      setOpenProductId(id);
    }
  };
  const handleOptionToggle = (productId, groupName, optionName, type) => {
    setSelectedOptions((prevState) => {
      const productOptions = prevState[productId] || {};
      const groupOptions = productOptions[groupName] || {};

      let newOptions;

      if (type === "multi-selection") {
        newOptions = {
          ...prevState,
          [productId]: {
            ...productOptions,
            [groupName]: {
              ...groupOptions,
              [optionName]: !groupOptions[optionName],
            },
          },
        };
      } else {
        // single-selection
        newOptions = {
          ...prevState,
          [productId]: {
            ...productOptions,
            [groupName]: {
              [optionName]: true,
            },
          },
        };
      }

      // If the product isn't already selected, select it
      if (!selectedProductQuantity[productId]) {
        setSelectedProductQuantity({
          ...selectedProductQuantity,
          [productId]: 1,
        });
      }

      return newOptions;
    });
  };
  const handleQRCodeButtonClick = () => {
    const orderStr = generateOrderString();
    console.log(orderStr); // Log or handle as needed
    setOrderString(orderStr); // Save order string to state
    if (!isQRCodeVisible) {
      setIsQRCodeVisible(true); // Show QR code
    } else {
      setIsQRCodeVisible(false); // Hide QR code
    }
  };
  const calculateSubtotal = (product) => {
    let subtotal = 0;
    const productQuantity = selectedProductQuantity[product.id]
      ? selectedProductQuantity[product.id]
      : 1;

    subtotal += product.price * productQuantity;

    if (selectedOptions[product.id]) {
      product.groups.forEach((group) => {
        group.options.forEach((option) => {
          if (selectedOptions[product.id][group.name]?.[option.name]) {
            subtotal += option.price * productQuantity;
          }
        });
      });
    }

    return subtotal;
  };
  const calculateQty = (productId) => {
    let qty = 0;
    cart.forEach((item) => {
      item.id === productId ? (qty += item.quantity) : (qty += 0);
    });

    return qty;
  };
  const calculateTotal = () => {
    let total = 0;

    Object.values(cart).forEach((item) => {
      total += item.subtotal;
    });

    return total;
  };

  const generateOrderString = () => {
    return (
      "phoneorder" +
      cart
        .map((item) => {
          // Extract the id and quantity directly
          const itemStr = `^^${item.id},${item.quantity}`;

          // Extract and format the options
          const optionsStr = Object.entries(item.options)
            .map(([group, options]) => {
              const selectedOptions = Object.keys(options)
                .filter((optionName) => options[optionName])
                .join(",");
              return `,${group},${selectedOptions}`;
            })
            .join("");

          return itemStr + optionsStr;
        })
        .join("")
    );
  };

  const handleAddToCart = (product) => {
    // Prepare the cart item
    const cartItem = {
      no: cart.length + 1,
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: selectedProductQuantity[product.id]
        ? selectedProductQuantity[product.id]
        : 1,
      options: selectedOptions[product.id] || {},
      subtotal: calculateSubtotal(product),
    };

    // Add the item to the cart
    setCart((prevCart) => [...prevCart, cartItem]);

    // Clear the product selection
    setSelectedProductQuantity((prevState) => {
      const newState = { ...prevState };
      delete newState[product.id];
      return newState;
    });
    setSelectedOptions((prevState) => {
      const newState = { ...prevState };
      delete newState[product.id];
      return newState;
    });
  };
  const handleRemoveItem = (itemNo) => {
    // Create a new array without the removed item
    const newCart = cart.filter((item) => item.no !== itemNo);
    // Update the state
    setCart(newCart);
  };
  const areRequiredOptionsSelected = (product, selectedOptionsForProduct) => {
    // Check if product has groups and some of them are required
    if (!product.groups || !product.groups.some((group) => group.required)) {
      return true; // No required options to select
    }

    // Check all groups
    for (const group of product.groups) {
      // If group is required and no option is selected, return false
      if (
        group.required &&
        (!selectedOptionsForProduct ||
          !selectedOptionsForProduct[group.name] ||
          Object.values(selectedOptionsForProduct[group.name]).every(
            (opt) => !opt
          ))
      ) {
        return false;
      }
    }

    return true; // All required options are selected
  };

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const responseData = await getStore(storeIdParam.storeId);
        if (responseData.code === "000") {
          setStore(responseData.store);
          setLoading(false);
        }
      } catch (err) {
        setError(err.message || "An error occurred.");
        setLoading(false);
      }
    };
    const fetchProducts = async () => {
      try {
        const responseData = await getProducts(storeIdParam.storeId);

        if (responseData.code === "000") {
          let products = [...responseData.products].sort(
            (a, b) => a.slot - b.slot
          );
          setProducts(products);
          setLoading(false);
        }
      } catch (err) {
        setError(err.message || "An error occurred.");
        setLoading(false);
      }
    };
    fetchStore();
    fetchProducts();
  }, []);
  const handleSnakeClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakeOpen(false);
  };

  return (
    <div>
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: theme.palette.background.default,
          paddingBottom: "12vh",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <AppBar
          position="relative"
          variant="primary"
          elevation={2}
          sx={{
            bgcolor: theme.palette.background.component,
            borderRadius: "10px",
            padding: "0 15px",
            marginTop: "5px",
          }}
        >
          <Toolbar style={{ display: "flex", alignItems: "center" }}>
            <IconButton color="white" aria-label="menu">
              <img height={32} src="/logo64.png" alt="logo" />
            </IconButton>

            <Typography color={"white"} variant="h5">
              {store?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box height={"10vh"} margin={1} display={"flex"} justifyContent={"center"} bgcolor={'red'}>
          <Typography align="center" alignSelf={"center"} fontWeight={'bold'} color={'white'}>
            彩色粉粿依當天製作種類由店家為您搭配
          </Typography>
        </Box>
        <List sx={{ paddingTop: "1%" }}>
          {products.map((product, index) => (
            <React.Fragment key={product.id}>
              <Card
                key={"card" + product.id}
                variant="elevation"
                sx={{
                  bgcolor: theme.palette.background.secondary,
                  marginBottom: "5px",
                }}
              >
                <ListItemButton
                  onClick={() => toggleCollapse(product.id)}
                  disabled={!product?.enabled}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* Product Name and Price */}
                  <Stack direction="column">
                    <Typography
                      fontSize={18}
                      style={{ justifySelf: "flex-start" }}
                    >
                      {product?.enabled
                        ? `${index + 1}. ${product.name} $${product.price}`
                        : `${index + 1}. ${product.name} $${
                            product.price
                          } (已售完)`}
                    </Typography>
                    <Typography color={theme.palette.text.secondary}>
                      {product.description}
                    </Typography>
                  </Stack>
                  {/* Container for Chip and Expand Icon */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* Product Quantity Chip */}
                    {calculateQty(product.id) > 0 && (
                      <Chip
                        icon={<ShoppingBasketIcon />}
                        label={
                          calculateQty(product.id) > 0
                            ? `${calculateQty(product.id)}`
                            : null
                        }
                        size="small"
                        color="warning"
                        style={{ marginRight: "8px" }} // Add some space between Chip and icon
                      />
                    )}
                    {/* Expand Icon */}
                    {openProductId === product.id ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </div>
                </ListItemButton>

                <Collapse
                  in={openProductId === product.id}
                  timeout="auto"
                  unmountOnExit
                >
                  {product.groups?.map((group) => (
                    <List component="div" key={group.name}>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography
                          marginTop={2}
                          fontWeight={"bold"}
                          fontSize={18}
                          style={{ paddingLeft: 40 }}
                        >
                          {group.name}
                        </Typography>
                        {group.required ? (
                          <Chip
                            sx={{
                              margin: "13px",
                              bgcolor: theme.palette.background.component,
                              color: "white",
                            }}
                            size="small"
                            variant="contained"
                            label="必選"
                          ></Chip>
                        ) : null}
                      </Stack>

                      {/* Check if the group type is single-selection and wrap with RadioGroup */}
                      {group.type === "single-selection" ? (
                        <RadioGroup>
                          {group.options.map((option) => (
                            <FormControlLabel
                              key={option.name}
                              style={{ paddingLeft: 50 }}
                              control={
                                <Radio
                                  color="warning"
                                  disabled={!option?.enabled}
                                  checked={
                                    selectedOptions[product.id]?.[group.name]?.[
                                      option.name
                                    ] || false
                                  }
                                  onChange={() =>
                                    handleOptionToggle(
                                      product.id,
                                      group.name,
                                      option.name,
                                      group.type
                                    )
                                  }
                                />
                              }
                              label={
                                option?.enabled
                                  ? option.name + ` $${option.price}`
                                  : option.name + ` $${option.price}(已售完)`
                              }
                            />
                          ))}
                        </RadioGroup>
                      ) : (
                        <FormGroup style={{ paddingLeft: 40 }}>
                          {group.options.map((option) => (
                            <FormControlLabel
                              key={option.name}
                              control={
                                <Checkbox
                                  color="warning"
                                  disabled={!option?.enabled}
                                  checked={
                                    selectedOptions[product.id]?.[group.name]?.[
                                      option.name
                                    ] || false
                                  }
                                  onChange={() =>
                                    handleOptionToggle(
                                      product.id,
                                      group.name,
                                      option.name,
                                      group.type
                                    )
                                  }
                                />
                              }
                              label={
                                option?.enabled
                                  ? option.name + ` $${option.price}`
                                  : option.name + ` $${option.price}(已售完)`
                              }
                            />
                          ))}
                        </FormGroup>
                      )}
                      <Divider />
                    </List>
                  ))}
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedProductQuantity((prev) => ({
                            ...prev,
                            [product.id]: Math.max(
                              1,
                              (prev[product.id] || 1) - 1
                            ),
                          }));
                        }}
                      >
                        <RemoveCircleSharpIcon
                          fontSize="large"
                          sx={{ color: theme.palette.background.component }}
                        />
                      </IconButton>
                      <Typography alignSelf="center" fontSize={"large"}>
                        {selectedProductQuantity[product.id] > 0
                          ? selectedProductQuantity[product.id]
                          : 1}
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedProductQuantity((prev) => ({
                            ...prev,
                            [product.id]: (prev[product.id] || 1) + 1,
                          }));
                        }}
                      >
                        <AddCircleSharpIcon
                          fontSize="large"
                          sx={{ color: theme.palette.background.component }}
                        />
                      </IconButton>
                    </Stack>

                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCollapse(product.id);
                        handleAddToCart(product);
                        setSnakeOpen(true);
                      }}
                      sx={{
                        width: "60%",
                        fontSize: "12pt",
                        fontWeight: "bold",
                        bgcolor: theme.palette.background.component,
                      }}
                      disabled={
                        !areRequiredOptionsSelected(
                          product,
                          selectedOptions[product.id]
                        )
                      }
                    >
                      {`加入購物車 $${calculateSubtotal(product)}`}
                    </Button>
                  </Stack>
                </Collapse>
              </Card>
            </React.Fragment>
          ))}
        </List>
      </Container>

      <Stack
        position={"fixed"}
        sx={{
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          height: "10vh",
          paddingTop: "1vh",
          zIndex: 1,
        }}
      >
        <Button
          sx={{
            fontWeight: "bold",
            fontSize: "large",
            width: "95%",
          }}
          variant="contained"
          color="warning"
          startIcon={<ShoppingCartSharpIcon />}
          onClick={() => {
            setIsDialogOpen(true); // This will open the dialog
          }}
        >
          {`查看購物車 $${calculateTotal()}`}
        </Button>
      </Stack>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          fontWeight={"bold"}
          id="alert-dialog-title"
          fontSize={22}
          sx={{ bgcolor: theme.palette.background.default }}
        >
          {"購物車"}
        </DialogTitle>
        <DialogContent
          sx={{ bgcolor: theme.palette.background.default, minHeight: "50vh" }}
        >
          {cart.map((item) => (
            <Card key={"cartitem" + item.no} sx={{ marginBottom: 1 }}>
              <CardContent>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  spacing={1}
                  alignItems="center"
                >
                  {/* Quantity Chip */}
                  <Chip
                    icon={
                      <ShoppingBasketIcon fontSize="medium" color="white" />
                    }
                    size="small"
                    label={item.quantity}
                    sx={{
                      fontWeight: "bold",
                      bgcolor: theme.palette.background.component,
                      color: "white",
                    }}
                  />

                  <Stack direction={"column"}>
                    {/* Product Name and Subtotal */}
                    <Typography variant="subtitle1">
                      {item.name} ${item.subtotal}
                    </Typography>

                    {/* Options Display */}
                    <Stack direction={"row"} spacing={1}>
                      {Object.entries(item.options).map(
                        ([category, options]) => (
                          <Typography variant="caption" key={category}>
                            {/* Concatenating the category name and its selected options */}
                            {`${Object.keys(options)
                              .filter((optionKey) => options[optionKey])
                              .join(", ")}`}
                          </Typography>
                        )
                      )}
                    </Stack>
                  </Stack>
                  <IconButton onClick={() => handleRemoveItem(item.no)}>
                    <DeleteForeverRoundedIcon></DeleteForeverRoundedIcon>
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </DialogContent>
        {isQRCodeVisible && (
          <Grow
            in={isQRCodeVisible}
            style={{ transformOrigin: "50% 100% 0" }}
            timeout={500}
          >
            <Box
              border={3}
              borderRadius={3}
              borderColor={"gray"}
              paddingBottom={5}
              height={"30vh"}
              width={"79vw"} // adjust according to your design
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="absolute" // or "absolute" depending on your layout needs
              top={"25%"}
              left={"2%"}
              bgcolor={"white"}
              zIndex={1500} // ensure the Box appears above all other content
            >
              <Typography mt={3} mb={1} fontSize={"18pt"} fontWeight={"bold"}>
                請直接於點餐機掃描付款
              </Typography>
              <QRCodeSVG value={orderString} size={128} />
            </Box>
          </Grow>
        )}
        <DialogActions
          sx={{
            justifyContent: "space-between",
            bgcolor: theme.palette.background.default,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setIsDialogOpen(false);
              setIsQRCodeVisible(false);
            }}
            color="primary"
          >
            關閉
          </Button>
          <Typography fontSize={20}> ${calculateTotal()}</Typography>
          <Button
            sx={{
              fontWeight: "bold",
              fontSize: "large",
              width: "50%",
            }}
            variant="contained"
            color="warning"
            startIcon={<QrCode2Icon />}
            onClick={handleQRCodeButtonClick}
          >
            {isQRCodeVisible ? "隱藏條碼" : `點餐條碼`}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snakeOpen}
        autoHideDuration={2000}
        onClose={handleSnakeClose}
        sx={{ marginBottom: "8vh" }}
      >
        <Alert
          onClose={handleSnakeClose}
          severity="success"
          sx={{ width: "80%", fontSize: "16pt", fontWeight: "bold" }}
        >
          成功加入購物車!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default StorePage;
