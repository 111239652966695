import axios from 'axios';

/**
 * Fetches products for a given store ID.
 *
 * @param {string} storeId - The ID of the store.
 * @return {Promise<Array>} - A list of products for the given store ID.
 * @throws {Error} - If the request fails or storeId is not provided.
 */
const getProducts = async (storeId) => {
  if (!storeId) {
    throw new Error('Store ID is required.');
  }

  const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/products/get`;
  
  try {
    const response = await axios.get(endpoint, {
      params: {
        store_id: encodeURIComponent(storeId)
      }
    });

    return response.data;
  } catch (error) {
    console.error(`Error while fetching products for store ${storeId}:`, error);
    throw error;
  }
}

export default getProducts;
