
import api from '../api';



const getStore = async (storeId) => {
  try {
    const response = await api.get(process.env.REACT_APP_API_ENDPOINT + "/api/store/get?store_id=" + encodeURIComponent(storeId));
console.log(response.data)
    return response.data;

  } catch (error) {
    console.error(error)
    return error;
  }
}; // Removed requestData from dependencies


export default getStore;
